import { useEffect, useState } from 'react';
import Prismic from '@prismicio/client';
const apiEndpoint = 'https://baubocare.cdn.prismic.io/api/v2'
const accessToken = 'MC5YLW5US1JBQUFDUUFnYlRK.77-977-9F--_vQI977-977-9JHfvv71977-977-9Ru-_vSFW77-9FGYs77-9VhF2Xu-_vW9sVlI'

const Client = Prismic.client(apiEndpoint, { accessToken })

export const usePrismic = (query, options = {}) => {
	const [doc, setDocData] = useState(null)

	useEffect(() => {
		if (query) {
			Client.query(query, options).then(response => {
				if (response) {
					setDocData(response.results)
				}
			})
		}
	}, [query, options]);

	return doc;
}

export const useLocalStorage = (key, initialValue) => {
	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState(() => {
		try {
			// Get from local storage by key
			const item = window.localStorage.getItem(key);
			// Parse stored json or if none return initialValue
			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			// If error also return initialValue
			console.log(error);
			return initialValue;
		}
	});
	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to localStorage.
	const setValue = (value) => {
		try {
			// Allow value to be a function so we have same API as useState
			const valueToStore =
				value instanceof Function ? value(storedValue) : value;
			// Save state
			setStoredValue(valueToStore);
			// Save to local storage
			window.localStorage.setItem(key, JSON.stringify(valueToStore));
		} catch (error) {
			// A more advanced implementation would handle the error case
			console.log(error);
		}
	};
	return [storedValue, setValue];
}