import React, {useEffect, useState} from "react"
import {Link} from "gatsby"
import Prismic from '@prismicio/client';
import loadable from '@loadable/component'

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
	Container,
	Row,
	Col,
	Card,
	ProgressBar,
	Button,
	Accordion,
	Badge,
	OverlayTrigger,
	Popover,
	Modal, Form
} from "react-bootstrap";
import {RadialChart} from "react-vis";
import {FaArrowRight, FaCheckCircle, FaChevronDown, FaInfoCircle} from "react-icons/fa";
import {usePrismic, useLocalStorage} from "../utils/hooks";
import RichText from "prismic-reactjs/src/Component";


const EventbriteButton = loadable(() => import('react-eventbrite-popup-checkout'))

const Insights = () => {
	const search = typeof window !== 'undefined' ? window.location.search : '';
	const urlParams = new URLSearchParams(search);
	const [storedToken, storeToken] = useLocalStorage('storedToken', null);
	const token = urlParams.get('token') || storedToken;
	const [insights, setInsights] = useState({});
	const [showPaymentModal, setShowPaymentModal] = useState(false);
	const [donation, setDonation] = useState(3);
	const [willContribute, setWillContribute] = useState(false);
	let email;
	if (token) {
		email = atob(token);
	}
	useEffect(() => {
		if (email) {
			storeToken(btoa(email));
			fetch(`/.netlify/functions/get-insights?email=${encodeURIComponent(email)}`)
				.then((res) => res.json())
				.then((res) => {
					setInsights(res.fields);
				})
		}
	}, [email]);
	const occupational = (insights.OccupationalQOL * 100) / 35;
	const health = (insights.HealthQOL * 100) / 35;
	const emotional = (insights.EmotionalQOL * 100) / 30;
	const sexual = (insights.SexualQOL * 100) / 15;

	const articles = usePrismic(insights.Symptoms ? [
		Prismic.Predicates.at('document.type', 'blog'),
		Prismic.Predicates.any('document.tags', insights.Symptoms.map(i => i.toUpperCase())),
	] : '', { pageSize: 3 });
	const qolThreshold = 70;
	const tagMap = {
		emotional,
		occupational,
		health,
		sexual
	}
	const variantMap = {
		emotional: 'success',
		occupational: 'warning',
		health: 'info',
		sexual: 'danger',
	}
	const tags = Object.keys(tagMap).reduce((acc, key) => {
		const val = tagMap[key];
		if (val < qolThreshold) {
			return [...acc, `${key.toUpperCase()} ASSESSMENT MEDIUM`, `${key.toUpperCase()} ASSESSMENT LOW`];
		} else {
			return [...acc, `${key.toUpperCase()} ASSESSMENT HIGH`]
		}
	}, []);
	const qolAdvice = usePrismic(insights.TotalQOL ? [
		Prismic.Predicates.at('document.type', 'advice'),
		Prismic.Predicates.any('document.tags', tags)
	] : '');
	const recommendations = usePrismic(insights.Symptoms ? [
		Prismic.Predicates.at('document.type', 'advice'),
		Prismic.Predicates.any('document.tags', insights.Symptoms.map(i => i.toUpperCase()))
	] : '');

	return (
		<Layout>
			<SEO title="Health insights" />
			<div className="bg-light">
				<Container>
					<Row>
						<Col>
							<h1 className="mt-3">My Insights</h1>
							<Row className="py-3 align-items-center">
								<Col xs="12" md="4" lg="6" className="d-flex flex-column align-items-center justify-content-center mb-3 mb-md-0" style={{ minHeight: '200px' }}>
									<RadialChart
										className="position-absolute"
										data={[{angle: insights.TotalQOL, style: { fill: '#cc6882', stroke: '#cc6882' }}, {angle: 100 - insights.TotalQOL, style: { fill: '#e9ecef', stroke: '#e9ecef' }}]}
										innerRadius={85}
										radius={95}
										width={200}
										height={200} />
									<h1 className="display-4">{insights.TotalQOL}</h1>
									<div className="d-flex align-items-start position-relative">
										<h4 className="text-muted">QOL</h4>
										<OverlayTrigger
											trigger="click"
											overlay={(
												<Popover id="qol-info-contained" content>
													The total quality of life measured based on your responses. The instrument used for assessment was developed by The North American Menopause Society and adapted for digital distribution by Baushe.
												</Popover>
											)}
										>
											<small className="text-muted" style={{ cursor: "pointer" }}><FaInfoCircle /></small>
										</OverlayTrigger>
									</div>
								</Col>
								<Col xs="12" md="8" lg="6">
									<div className="d-md-flex align-items-center mb-1">
										<span className="mr-2 w-25">Occupational</span><ProgressBar variant={variantMap.occupational} className="flex-grow-1" now={occupational} label={occupational ? `${occupational.toFixed(0)}%` : ''} />
									</div>
									<div className="d-md-flex align-items-center mb-1">
										<span className="mr-2 w-25">Health</span><ProgressBar variant={variantMap.health} className="flex-grow-1" now={health} label={health ? `${health.toFixed(0)}%` : ''} />
									</div>
									<div className="d-md-flex align-items-center mb-1">
										<span className="mr-2 w-25">Emotional</span><ProgressBar variant={variantMap.emotional} className="flex-grow-1" now={emotional} label={emotional ? `${emotional.toFixed(0)}%` : ''} />
									</div>
									<div className="d-md-flex align-items-center mb-1">
										<span className="mr-2 w-25">Sexual</span><ProgressBar variant={variantMap.sexual} className="flex-grow-1" now={sexual} label={sexual ? `${sexual.toFixed(0)}%` : ''} />
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs="12">
									<h3 className="mt-3">Results</h3>
									<p>
										Based on your responses, your overall quality of life score is {insights.TotalQOL} which is {insights.TotalQOL > 74 ? 'above' : 'below'} the mean. This assessment gives you insights on the status of 4  areas that influence quality of life, taking into account an overall sense of well-being, beyond your symptoms. These areas are: Emotional, Health, Occupational and Sexual aspects. Here below we share some useful information on how to address the different aspects assessed, so you can take your quality of life to the next level. You will also find additional tips on how to manage your most impactful symptoms and other expert-backed literature so you can experience this stage on your terms.
										<br/>
										<small className="mt-1 text-muted">*This assessment is based on the work of Dr. Wulf H. Utian PhD (A Menopause expert, with over 50 years of experience, and one of the founders of the International Menopause Society).</small>
									</p>
								</Col>
							</Row>
							<Row className="my-4">
								<Col xs="12" md="10" lg="8" className="mx-auto">
									<div className="p-3 rounded shadow bg-white">
										<h2>Ready to take control of your menopause journey?</h2>
										<p>
											Meet women like you in Baushe Circles - personalized menopause support groups. Access reliable information on menopause, share your experiences and build new habits with the best wing-women for your transition.
										</p>
										<Button size="lg" as={Link} to="/circles">Join a Baushe Circle</Button>
									</div>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col xs="12">
									<h3 className="mt-3">Personalized advice</h3>
									<Accordion>
										<Card>
											<Card.Header>
												<Accordion.Toggle as={Button} variant="link" eventKey="0" className="p-0 w-100 d-flex justify-content-between align-items-center">
													<span>Tips to improve your quality of life</span>
													<FaChevronDown />
												</Accordion.Toggle>
											</Card.Header>
											<Accordion.Collapse eventKey="0">
												<Card.Body>
													{qolAdvice && qolAdvice.map(({ data: { content, labels }, tags }) => (
														<div>
															{labels && (
																<Badge variant={variantMap[tags.reduce((acc, item) => item.split(' ')[0], '').toLowerCase()]}>{labels}</Badge>
															)}
															<p className="mt-1">{RichText.render(content)}</p>
														</div>
													))}
												</Card.Body>
											</Accordion.Collapse>
										</Card>
										<Card>
											<Card.Header>
												<Accordion.Toggle as={Button} variant="link" eventKey="1" className="p-0 w-100 d-flex justify-content-between align-items-center">
													<span>Symptom management advice</span>
													<FaChevronDown />
												</Accordion.Toggle>
											</Card.Header>
											<Accordion.Collapse eventKey="1">
												<Card.Body>
													{recommendations && recommendations.map(({ data: { content }, tags }) => (
														<div>
															{tags.length && (
																<Badge variant="light" className="text-capitalize">
																	{tags[0].toLowerCase()}
																</Badge>
															)}
															<p>{RichText.render(content)}</p>
														</div>
													))}
												</Card.Body>
											</Accordion.Collapse>
										</Card>
									</Accordion>
								</Col>
							</Row>
							<Row className="mb-5">
								<Col xs="12">
									<h3 className="mt-3">Content for you</h3>
									<div className="row align-items-stretch">
										{articles && articles.map((node) => {
											const { title, content, preview_img: { url: previewUrl } } = node.data;
											return (
												<Col xs="12" md="4" className="mb-3">
													<Card className="shadow-sm mr-3 h-100">
														<Card.Img variant="top" src={previewUrl} />
														<Card.Body>
															<Card.Title>{RichText.asText(title)}</Card.Title>
															<Card.Text className="text-muted">{RichText.asText(content).slice(0, 150)}...</Card.Text>
															<div className="text-right">
																<Link to={`/${node.uid}`}>Read more <FaArrowRight /></Link>
															</div>
														</Card.Body>
													</Card>
												</Col>
											)
										})}
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
			<Modal show={showPaymentModal} onHide={() => setShowPaymentModal(false)}>
				{!willContribute && (
					<Modal.Header closeButton>
						<Modal.Title>Do you find this information useful?</Modal.Title>
					</Modal.Header>
				)}
				{!willContribute && (
					<Modal.Body>
						Would you consider a contribution so we can continue building Baushe?
						<Form>
							<Form.Group controlId="formBasicRange" className="py-3">
								<Form.Label className="text-info d-block mb-0 text-center display-4">
									${donation}
								</Form.Label>
								<div className="text-center font-weight-bold text-info mb-3">per month</div>
								<Form.Control type="range" min="1" max="10" value={donation} onChange={e => setDonation(e.target.value)} />
							</Form.Group>
						</Form>
					</Modal.Body>
				)}
				{willContribute && (
					<Modal.Body>
						<div className="text-center text-success h1 py-3">
							<FaCheckCircle />
						</div>
						<div className="h3">Thank you for willing to help us!</div>
						<p className="text-muted">
							We do our best to make Baushe great and support millions of women going through menopause around the world.
							Your input is super valuable to us.
						</p>
					</Modal.Body>
				)}
				{!willContribute && (
					<Modal.Footer>
						<Button variant="link" onClick={() => setShowPaymentModal(false)}>
							No thanks
						</Button>
						<Button variant="primary" onClick={() => setWillContribute(true)}>
							Contribute
						</Button>
					</Modal.Footer>
				)}
				{willContribute && (
					<Modal.Footer>
						<Button variant="outline-primary" onClick={() => setShowPaymentModal(false)}>
							Close
						</Button>
					</Modal.Footer>
				)}
			</Modal>
		</Layout>
	);
}

export default Insights
